<template>
  <div>
    <!-- <Cashier /> -->
    <b-container fluid>
      <b-row>
        <CartProduct />
        <b-col class="px-0 border-right-light border-right-line bg-gray-2" cols="12" md="8" lg="8"
          style="position: relative;height:100vh;">
          <ListCarts :is_add="is_add" @getIsAdd="getIsAdd" @item="detailProductDefault" ref="listProductsSearchSection" />
          <ListProducts @item="detailProductDefault" ref="listProducts" />
        </b-col>
      </b-row>
    </b-container>
    <ModalAddtionalInputDiscount />
    <ModalTutorial />
    <b-modal id="modal-add-product" hide-header hide-footer centered title="Vertically Centered" ok-only
      ok-title="Accept">
      <div class="p-2 d-flex justify-content-center text-center">
        <h4 class="text-dark font-bold-700 size16 mb-0">
          Tambah Produk
        </h4>
      </div>
      <b-card no-body class="mb-1 border-12 mx-2 border" style="box-shadow: unset;">
        <b-card-body class="p-2">
          <div class="d-flex">
            <div class="image__container">
              <b-img :src="resultDetail.image_url || ''" width="47" height="47" alt="image" />
            </div>
            <div class="product__information w-100 d-flex justify-content-between">
              <div class="d-flex flex-column w-100">
                <div class="d-flex justify-content-between mb-25">
                  <h1 class="h5 text-dark fw-bold-800 mb-0 size14">
                    {{ resultDetail.alias_name || '' }}
                  </h1>
                  <h1 class="h5 text-dark font-weight-bolder mb-0">
                    {{ resultDetail.standard_price | formatAmount }}
                  </h1>
                </div>
                <div class="d-flex justify-content-between">
                  <small class="fw-bold-500 text-dark text-darken-4">{{ resultDetail.stock || 0 }} {{ resultDetail.unit
                    || '' }}</small>
                </div>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
      <b-container class="mb-md-3 mb-2">
        <b-row class="p-2">
          <b-col cols="12">
            <div class="mb-2 custom__form--input" v-if="!resultDetail.is_default">
              <label>Nama Produk <span class="text-danger">*</span></label>
              <b-form-input id="search-product" v-model="formPayloadCheck.alias_name" class="focus-0 custom__input"
                type="text" placeholder="Nama Produk" />
            </div>
            <div class="mb-2" v-if="!resultDetail.is_default">
              <label for="v-satuan" class="text-dark fw-bold-600 size14">Satuan <span
                  class="text-danger">*</span></label>
              <v-select v-model="formPayloadCheck.uom_uuid" placeholder="Pilih Satuan" label="name" taggable
                :options="optionUom" @search="onSearchUom" />
            </div>
            <div class="mb-2 custom__form--input">
              <label for="harga-beli">Harga Jual Standard</label>
              <input id="harga-jual-standard" class="focus-0 custom__input pl-1 w-100" type="text"
                @input="getRupiahStandard" @change="getRupiahStandard">
            </div>
            <div class="mb-2 custom__form--input">
              <label for="harga-beli">Harga Jual Minimum</label>
              <input id="harga-jual-minimum" class="focus-0 custom__input pl-1 w-100" type="text"
                @input="getRupiahMinimum" @change="getRupiahMinimum">
            </div>
            <div class="mb-2 custom__form--input">
              <label for="harga-beli">Harga Beli</label>
              <input id="harga-beli" class="focus-0 custom__input pl-1 w-100" type="text" @input="getRupiah"
                @change="getRupiah">
            </div>
            <div class="mb-2 custom__form--input">
              <label class="mb-1">
                Pilih Lokasi SKU <span class="text-danger">*</span>
              </label>
              <div v-for="(warehouse, index) in warehouses" :key="index">
                <div class="d-flex justify-content-between mb-1">
                  <h6 class="text-dark fw-bold-700">
                    {{ warehouse.name }}
                  </h6>
                  <b-form-checkbox :id="`yes-${index}`" :ref="`warehouseId${index}`"
                    v-model="formPayloadCheck.initial_stocks[index].warehouse_uuid" :value="warehouse.uuid"
                    class="custom-control-success">
                    Ya
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div class="button__group">
        <b-button class="button__cancel" @click="$bvModal.hide('modal-add-product')" id="button--hide__product">
          Batal
        </b-button>
        <b-button class="button__save d-flex align-items-center justify-content-center" :disabled="isSaveButtonDisabled"
          @click.prevent="createItem" id="button--save__product">
          <div v-if="isLoadingProduct">
            <b-spinner small variant="primary" />
          </div>
          Simpan
        </b-button>
      </div>
    </b-modal>
    <ModalPaymentPos :getActiveCart="getActiveCart" />
    <!-- <ModalAddProductToCart /> -->
  </div>
</template>

<script>
import _ from 'lodash'
import {
  BContainer, BRow, BCol, BButton, BFormCheckbox, BSpinner, BFormInput, BCard, BCardBody, BImg,
} from 'bootstrap-vue'

import { checkPermission, successNotification, errorNotification, preformatFloat } from '@/auth/utils'

import vSelect from 'vue-select'

export default {

  title() {
    return 'Kasir'
  },
  components: {
    BContainer,
    BRow,
    BCol,
    BButton, BFormCheckbox, BSpinner, BFormInput, BCard, BCardBody, BImg, vSelect,
    // ModalAddProductToCart,

    ListCarts: () => import('@/components/Pos2/ListCarts.vue'),
    ListProducts: () => import('@/components/Pos2/ListProducts.vue'),
    CartProduct: () => import('@/components/Pos2/Cart/CartProduct.vue'),
    ModalAddtionalInputDiscount: () => import('@/components/Pos2/Modal/AdditionalInputDiscount.vue'),
    AppTour: () => import('@core/components/app-tour/AppTour.vue'),
    ModalTutorial: () => import('@/components/ModalTutorial.vue'),
    ModalPaymentPos: () => import('@/components/Pos2/Modal/Payment.vue'),
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
      preformatFloat
    }
  },
  computed: {
    isSaveButtonDisabled() {
      const noWarehouseUUID = this.formPayloadCheck.initial_stocks.every(e => e.warehouse_uuid === '');
      if (this.resultDetail.is_default) {
        return noWarehouseUUID || this.isLoadingProduct;
      } else {
        return noWarehouseUUID ||
          this.formPayloadCheck.alias_name === '' ||
          this.formPayloadCheck.uom_uuid === '';
      }
    }
  },
  data() {
    return {
      data: Object,
      is_add: false,
      userData: JSON.parse(localStorage.getItem('userData')),
      resultDetail: Object,
      formPayloadCheck: {
        product_type: 'parent',
        alias_name: '',
        uom_uuid: '',
        buying_price: null,
        minimum_price: null,
        standard_price: null,
        initial_stocks: [
          {
            warehouse_uuid: '',
          },
        ],
      },
      optionUom: [],
      warehouses: [],
      isLoadingProduct: false,
    }
  },
  watch: {
    '$store.state.cashier.userData': {
      handler(value) {
        this.userData = value
      },
      deep: true,
    },
    '$route.query.uuid': {
      handler(val) {
        if (!val) {
          localStorage.removeItem('customer')
        }
      },
      deep: true,
    },
    'formPayload.uom_uuid': function (value) {
      if (value?.label) {
        this.getUomDetail(value.label)
        // this.formPayload.uom_uuid = value.label
      }
    },
  },
  created() {
    if (checkPermission('kasir')) {
      this.getActiveCart()
      this.userData = JSON.parse(localStorage.getItem('userData'))
    } else {
      this.$router.push('/')
    }
    localStorage.setItem('customer_uuid', '')
  },
  mounted() {
    localStorage.removeItem('delivery_pickup')
    localStorage.removeItem('customer_supplier_uuid')
    this.getWarehouse()
    this.getUom()

    const observer = new PerformanceObserver((list) => {
      list.getEntries().forEach((entry) => {
        if (entry.type == 'reload') {
          localStorage.removeItem('customer_data')
        }
      });
    });

    observer.observe({ type: "navigation", buffered: true });
  },
  methods: {
    getWarehouse() {
      this.$store.dispatch('warehouse/getData', {
        uuid: '',
        params: '',
      }).then(result => {
        this.warehouses = result.data.data
        for (let index = 0; index < result.data.data.length; index++) {
          this.formPayloadCheck.initial_stocks.push(
            {
              warehouse_uuid: '',
            },
          )
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getRupiahStandard() {
      const rupiah = document.getElementById('harga-jual-standard')
      rupiah.value = this.formatRupiah(rupiah.value, '')
      this.formPayloadCheck.standard_price = rupiah.value
    },
    getRupiahMinimum() {
      const rupiah = document.getElementById('harga-jual-minimum')
      rupiah.value = this.formatRupiah(rupiah.value, '')
      this.formPayloadCheck.minimum_price = rupiah.value
    },
    getRupiah() {
      const rupiah = document.getElementById('harga-beli')
      rupiah.value = this.formatRupiah(rupiah.value, '')
      this.formPayloadCheck.buying_price = rupiah.value
    },
    formatRupiah(angka, prefix) {
      const number_string = angka.replace(/[^,\d]/g, '').toString()
      const split = number_string.split(',')
      const sisa = split[0].length % 3
      let rupiah = split[0].substr(0, sisa)
      const ribuan = split[0].substr(sisa).match(/\d{3}/gi)

      if (ribuan) {
        const separator = sisa ? '.' : ''
        rupiah += separator + ribuan.join('.')
      }

      rupiah = split[1] != undefined ? `${rupiah},${split[1]}` : rupiah
      return prefix == undefined ? rupiah : (rupiah || '')
    },
    async getUomDetail(id) {
      await this.$store.dispatch('uom/getData', {
        uuid: id,
        params: '',
      })
        .then(result => {
          setTimeout(() => {
            this.optionUom.push({
              label: result.data.data.uuid,
              name: result.data.data.name,
            })
            const option = this.getUniqueListBy(this.optionUom, 'label')
            this.optionUom = option
          }, 500)
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    onSearchUom(search, loading) {
      if (search.length) {
        loading(true)
        this.searchUom(loading, search, this)
      } else {
        loading(true)
        setTimeout(() => {
          this.optionUom = []
          this.getUom()
          loading(false)
        }, 500)
      }
    },
    searchUom: _.debounce((loading, search, vm) => {
      vm.$store.dispatch('uom/getData', {
        uuid: '',
        params: {
          search: escape(search),
        },
      })
        .then(response => {
          // eslint-disable-next-line no-param-reassign
          vm.optionUom = []
          response.data.data.data.forEach(item => {
            vm.optionUom.push({
              label: item.uuid,
              name: item.name,
            })
          })
          loading(false)
        })
    }, 300),
    async getUom() {
      this.$store.dispatch('uom/getData', {
        uuid: '',
        params: '',
      })
        .then(result => {
          result.data.data.data.forEach(element => {
            this.optionUom.push({
              label: element.uuid,
              name: element.name,
            })
          })
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    clearWarehouse() {
      for (let index = 0; index < this.warehouses.length; index++) {
        this.formPayloadCheck.initial_stocks[index].warehouse_uuid = ''
      }
    },
    createItem() {
      if (this.resultDetail.is_default) {
        this.formPayloadCheck.product_uuid = this.resultDetail.uuid
        this.formPayloadCheck.alias_name = this.resultDetail.alias_name
        this.formPayloadCheck.uom_uuid = this.resultDetail.unit_uuid
      }

      const form = this.preparePayloadProduct()
      if (!this.resultDetail.is_default) {
        form.append('uom_uuid', this.formPayloadCheck.uom_uuid.label || this.formPayloadCheck.uom_uuid.name || this.formPayloadCheck.uom_uuid)
      } else {
        form.append('uom_uuid', this.resultDetail.unit_uuid)
      }

      this.isLoadingProduct = true
      this.$store.dispatch('parentProduct/postData', {
        query: '',
        payload: form,
      })
        .then(async () => {
          // await this.searchProduct()
          await this.clearWarehouse()
          successNotification(this, 'Success', 'Produk berhasil di buat')
          // this.filter.search = ''
          this.formPayloadCheck = {
            ...this.formPayloadCheck,
            product_type: 'parent',
            alias_name: '',
            uom_uuid: '',
          }
          this.isLoadingProduct = false
          this.$bvModal.hide('modal-add-product')
          this.$refs.listProductsSearchSection.getData()
          this.$refs.listProducts.getData()
        })
        .catch(() => {
          this.isLoadingProduct = false
        })
    },
    detailProductDefault({ item, type }) {
      this.resultDetail = item
      this.formPayload = {
        ...this.formPayload,
        discounts: [],
        is_bonus: 0,
        price: '0',
        qty: '0',
        warehouse_uuid: '',
      }
      this.id = item.uuid
      this.$bvModal.show(`modal-add-product-to-cart-${type}`)
    },
    preparePayloadProduct() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayloadCheck) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayloadCheck.hasOwnProperty(key) && this.formPayloadCheck[key] != null) {
          if (key !== 'initial_stocks' && key !== 'buying_price' && key !== 'minimum_price' && key !== 'standard_price' && key !== 'uom_uuid') {
            form.append(key, this.formPayloadCheck[key])
          }
          if (key === 'buying_price' || key === 'minimum_price' || key === 'standard_price') {
            form.append(key, parseFloat(this.preformatFloat(this.formPayloadCheck[key])))
          }
        }
      }
      if (this.formPayloadCheck.initial_stocks) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.formPayloadCheck.initial_stocks.length; index++) {
          const element = this.formPayloadCheck.initial_stocks[index]
          if (element.warehouse_uuid !== '' && element.warehouse_uuid !== false) {
            // eslint-disable-next-line no-restricted-syntax
            for (const key in element) {
              if (Object.hasOwnProperty.call(element, key)) {
                // eslint-disable-next-line no-use-before-define
                const items = element[key]
                form.append(`initial_stocks[${index}][${key}]`, items)
              }
            }
          }
        }
      }
      return form
    },
    finishTutorial() {
      this.$store.dispatch('tutorial/postData')
        .then(result => {
          const userData = JSON.stringify(result.data.data)
          localStorage.setItem('userData', userData)
        }).catch(err => {
          // eslint-disable-next-line no-console
        })
    },
    tourStart() {
      this.$tours.cashierTour1.start()
    },
    getIsAdd(value) {
      this.is_add = value
    },
    async getActiveCart() {
      await this.$store.dispatch('cart/getCart', {
        uuid: this.$route.query.uuid || '',
        params: '',
      }).then(result => {
        const item = result.data.data
        this.$store.commit('cart/SET_TOTAL_ADDITIONAL_COST', item.additional_fee_total)
        this.$store.commit('cart/setDiscountCart', item.discount_value)
        this.$store.commit('cart/setDiscountType', item.discount_type)
        this.$store.commit('cart/setDiscountTotal', item.discount_total)
        this.$store.commit('cart/setSubtotalCart', item.subtotal)
        this.$store.commit('cart/setTotalPrice', item.total)
        this.$store.commit('cart/setTotalBuy', item.items.length)
        this.$store.commit('cart/setCartUuid', item.uuid)
        this.$store.commit('cart/setResultCartActive', item)
        this.$store.commit('cashier/setProduct', item.items.length)
        this.$store.commit('cart/SET_FORM_ADDITIONAL_COST', localStorage.getItem('additional_cost') || null)
        this.$store.commit('customer/setCustomer', item.customer || {})

        localStorage.setItem('cartUuid', item.uuid)
        localStorage.setItem('cash_total_payment', item.total)
        localStorage.setItem('customer', JSON.stringify(item.customer))
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: 'Poppins', 'sans-serif';
}
</style>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/tour.scss';

.text-white2 {
  color: #E2E8F0;
}

.v-tour {
  .v-step {
    background-color: #334155;

    .v-step__arrow {
      border-color: #334155;
    }
  }
}

.search__box {
  display: flex;
  align-content: center;
  width: 100%;
  height: 100%;
  padding: 1.1rem 2rem;
  background: #f6f7fa;

  .search__icon {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .search__text {
    width: 100%;

    input {
      background: transparent;
      border: none;
      height: 50px;

      &.focus-0:focus {
        box-shadow: none !important;
      }
    }
  }
}

.btn-delete {
  &:hover {
    box-shadow: none !important;
  }
}

/* =============== CART PRODUCT =============== */
.cart__product--container {

  .floating__button {
    position: sticky;
    width: 100%;
    bottom: 0;
  }

  .scroll__cart {
    overflow-y: scroll;
    height: 73vh;
  }

  .card {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    border-radius: 12px;

    .rounded__frame {
      display: flex;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #D5F1EE;
      margin-right: 1rem;
    }

    .rounded__frame.print__logo {
      background: #E4F8F6;
    }
  }

  .card.card__discount {
    background: #F1F9F8;
    border-radius: 16px 16px 0px 0px;
    box-shadow: none;
  }

  .card.card__click:hover {
    cursor: pointer;
  }

  .b-avatar.badge-light-warning.custom-avatar {
    background-color: #FEF9F5;
    color: #E79242;
  }

  .media-list {

    .media {
      position: relative;

      .media-aside-right {
        margin-left: 0px !important;

        >div {
          width: 134px;
        }
      }

      .media-body {
        display: flex;
        flex-direction: column;
        margin-right: 5px;
        height: 18vh;
        justify-content: space-between;
      }

      &:not(:last-child)::after {
        content: '';
        width: 100%;
        position: absolute;
        bottom: -3vh;
        border-bottom: 1px solid #E4E5EC;
      }

      .floating__container {
        position: relative;

        .floating__close--button {
          position: absolute;
          top: 6px;
          width: 24px;
          height: 24px;
          right: 6px;
          background: #fff;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .media__image {
        width: 100%;
        height: 85px;
        object-fit: cover;
        border-radius: 8px;
      }

      .spinner__button {
        width: 100%;
        display: flex;
        padding: 0.5rem;
        background: $light--1;
        border-radius: 8px;

        #input-wrap {
          margin: 0px;
          padding: 0px;
        }

        .value-button {
          display: flex;
          justify-content: center;
          align-items: center;
          // border: 1px solid #ddd;
          margin: 0px;
          width: 65px;
          text-align: center;
          vertical-align: middle;
          background: #fff;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
          border-radius: 6px;
        }

        .value-button:hover {
          cursor: pointer;
        }

        input.qty {
          text-align: center;
          border: none;
          margin: 0px;
          width: 100%;
          background: transparent;
          height: 32px;

          &:focus {
            border: none;
            outline: none;
          }
        }

        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }
}
</style>
